import React, { useState } from 'react';
import { Link } from 'gatsby';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

import PreviousIssues from '../../components/previousIssues';
import BannerComponent from '../../components/page/bannerComponent';

const Magazine = (data) => {
  const [filterActive, setfilterActive] = useState(false);
  const [category, setCategory] = useState('0');

  let mysqlMagazine = { ...data.pageContext.product };
  let groups = data.pageContext.groups;

  if (!groups || !mysqlMagazine) return false;

  let added = {};

  return (
    <Layout>
      <SEO
        title={mysqlMagazine.title}
        description={mysqlMagazine.description}
        image={mysqlMagazine.banner_portrait}
      />
      <section className='magazine'>
        <div className='mb-4 text-center container'>
          <div>
            <select
              className='form-control bg-secondary mt-4 mb-2 magazine-filter'
              onChange={(e) => {
                if (e.target.value === '0') {
                  setfilterActive(false);
                } else {
                  setfilterActive(true);
                }

                setCategory(e.target.value);
              }}>
              {global.server === 'https://api.skygirlsci.com' ? (
                <option value='0'>Tous les articles</option>
              ) : (
                <option value='0'>All</option>
              )}

              {data.pageContext.categories.map((d, i) => {
                return (
                  <option value={d.mysqlId} key={i}>
                    {d.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </section>

      {!filterActive && (
        <section className='magazine-layout-container'>
          <div className='container px-3 magazine-details'>
            <section className='magazine'>
              <div className='py-3 text-center container'>
                <h1 className='intro-title'>{mysqlMagazine.title}</h1>

                <p className='my-4 text-dark'>{mysqlMagazine.description}</p>
              </div>
              <BannerComponent
                value={mysqlMagazine.banner_landscape}
                type={1}></BannerComponent>
            </section>
          </div>
          <div className='iterate-magazine-bg'>
            {groups.map((d, i) => {
              return (
                <section className='magazine' key={i}>
                  <div className='py-5 text-center container'>
                    {d && d.articles[0] && d.articles[0].priority === 1 && (
                      <Link to={'/article/' + d.articles[0].mysqlId}>
                        <h3 className='intro-title'>{d.articles[0].title}</h3>
                        <p className='my-4 text-dark'>
                          {d.articles[0].description}
                        </p>
                      </Link>
                    )}

                    {/* magazine groups here */}
                    <div className='magazine-cards'>
                      {d &&
                        d.articles.map((d1, i1) => {
                          let title = d1.title;
                          let date = d1.date;
                          let banner = d1.banner;

                          let card_types = {
                            1: (
                              <Link
                                to={'/article/' + d1.mysqlId}
                                className='article-card card-lg '>
                                <BannerComponent
                                  value={banner}
                                  type={1}></BannerComponent>
                              </Link>
                            ),
                            2: (
                              <Link
                                to={'/article/' + d1.mysqlId}
                                className='article-card card-md '>
                                <BannerComponent
                                  value={banner}
                                  type={1}></BannerComponent>
                                <div className='article-desc mt-3'>{title}</div>
                                <div className=' article-desc mt-1 font-weight-light'>
                                  {date}
                                </div>
                              </Link>
                            ),
                            3: (
                              <Link
                                to={'/article/' + d1.mysqlId}
                                className='article-card card-sm '>
                                <BannerComponent
                                  value={banner}
                                  type={1}></BannerComponent>
                                <div className='card-content ml-3'>
                                  <div className='article-desc'>{title}</div>
                                  <div className=' article-desc mt-1 font-weight-light'>
                                    {date}
                                  </div>
                                </div>
                              </Link>
                            ),
                          };

                          // console.log(d1);
                          if (!added[d1.mysqlId]) {
                            added[d1.mysqlId] = 1;
                            return card_types[d1.priority];
                          }
                        })}
                    </div>
                  </div>
                </section>
              );
            })}

            {/* <PreviousIssues title={'Previous Issues'}></PreviousIssues> */}
          </div>
        </section>
      )}

      {filterActive && (
        <section className='magazine-filter-content'>
          <div className='container mb-5'>
            <div className='magazine-cards'>
              {data.pageContext.articles
                .filter((d) => d.categoryId == category && d.status === 2)
                .map((d) => {
                  let title = d.title;
                  let date = d.date;
                  let banner = d.banner;

                  return (
                    <Link
                      to={'/article/' + d.mysqlId}
                      className='article-card card-sm '>
                      <BannerComponent
                        value={banner}
                        type={1}></BannerComponent>
                      <div className='card-content ml-3'>
                        <div className='article-desc'>{title}</div>
                        <div className=' article-desc mt-1 font-weight-light'>
                          {date}
                        </div>
                      </div>
                    </Link>
                  );
                })}
            </div>
          </div>
        </section>
      )}
    </Layout>
  );
};

export default Magazine;
